





























































import { Component, Vue, Prop } from 'vue-property-decorator';
import rules from '@/app/shared/validation-rules';
import { changePasswordAction } from '@publicApp/shared/actions';
import routesNames from '@/app/shared/utilities/routes-names';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import AppPublicLayout from '@publicApp/shared/components/AppPublicLayout.vue';

@Component({
  components: {
    FormComponent,
    AppPublicLayout,
  },
  data: (vm: any) => ({
    emailRules: [
      rules.required(vm.$i18n.t('field_required')),
      rules.emailFormat(vm.$i18n.t('invalid_email')),
    ],
    passwordRules: [rules.required(vm.$i18n.t('field_required'))],
    confirmPasswordRules: [rules.required(vm.$i18n.t('field_required'))],
  }),
})
export default class ForgetPassword extends Vue {
  public showOldPassword = false;
  public showConfirmPassword = false;
  public showNewPassword = false;
  public oldPassword = '';
  public newPassword = '';
  public confirmNewPassword = '';

  public async changePassword() {
    try {
      await changePasswordAction(this.oldPassword, this.newPassword);
      this.$router.push(routesNames.HOME);
    } catch (error) {
      throw new Error(`Error changing password => ${error.message}`);
    }
  }

  public passwordsMatch() {
    return (
      this.newPassword === this.confirmNewPassword || this.$t('passwords_dont_match')
    );
  }
}
